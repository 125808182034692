import BasePlugin from '../BasePlugin'

export default class StartingReportsQuery extends BasePlugin {
  async execute (row, column, cell) {
    if (row.object_id && row.record_id, row.card_id) {
      this.context.$parent.openRegistryCard({
        registryId: row.object_id,
        cardId: row.card_id,
        cardName: 'Отчет',
        recordId: row.record_id
      })
    }
  }
}
